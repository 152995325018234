<template>
  <div class="page">
    <el-scrollbar class="Yscrollbar" style="height: calc(100% - 8vh)">
      <LabelContent title="个人中心" />
      <el-form label-width="100px" class="u-form" size="small">
        <el-form-item label="用户名">
          <el-input v-model="userinfo.name" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <LabelContent title="密码修改" />
      <el-form label-width="100px" class="u-form" size="small">
        <el-form-item label="当前密码">
          <el-input
            :type="oldPwdShow ? 'password' : 'text'"
            placeholder="请输入"
            v-model="userinfo.oldPassword"
          >
            <i
              slot="suffix"
              class="iconfont"
              :class="oldPwdShow ? 'u-bukejian' : 'u-kejian'"
              @click="oldPwdShow = !oldPwdShow"
            />
          </el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input
            :type="newPwdShow ? 'password' : 'text'"
            placeholder="请输入"
            v-model="userinfo.newPassword"
          >
            <i
              slot="suffix"
              class="iconfont"
              :class="newPwdShow ? 'u-bukejian' : 'u-kejian'"
              @click="newPwdShow = !newPwdShow"
            />
          </el-input>
        </el-form-item>
      </el-form>
    </el-scrollbar>
    <div class="bottomBtnBox">
      <el-button class="long" @click="test" type="primary" size="small">修改</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Factory",
  data () {
    return {
      //密码不可见控制
      oldPwdShow: true,
      newPwdShow: true,
      userinfo: {
        name: '',
        email: '',
        phone: '',
        oldPassword: '',
        newPassword: ''
      }
    }
  },
  mounted () { },
  methods: {
    async test () {
      if (this.userinfo.name == '') {
        this.$message({
          message: '请输入用户名',
          type: 'error'
        })
      } else if (this.userinfo.oldPassword == '') {
        this.$message({
          message: '请输入当前密码',
          type: 'error'
        })
      } else if (this.userinfo.newPassword == '') {
        this.$message({
          message: '请输入新密码',
          type: 'error'
        })
      } else {
        let data = await this.$http
          .request({
            url: "/hw/admin/user/upd_self",
            method: "POST",
            showLoading: true,
            params: this.userinfo,
          })
        this.$message({
          message: '密码修改成功',
          type: 'success'
        })
        if (data.status == 100) {
          localStorage.setItem("tagView", '')
          this.lsSet('userInfo', {})
          this.jump('/login')
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
